import React, { FormEvent, useEffect, useState } from "react"
import { Accordion, Card, Container, Row, Col, Image, Button, Form } from "react-bootstrap"
import { Contract } from "web3-eth-contract"
import NoobsGif from "../../../images/Noobs.gif"
import SiteGrid from "../../../images/SiteGrid.jpg"
import sample1 from "../../../images/Sample-1.png"
import sample2 from "../../../images/Sample-2.png"
import sample3 from "../../../images/Sample-3.png"
import sample4 from "../../../images/Sample-4.png"
import sample5 from "../../../images/Sample-5.png"
import sample6 from "../../../images/Sample-6.png"
import { FaTwitter } from 'react-icons/fa';
import { isMobile } from "../../../utils/device"
import MetaMaskConnectButton from "../../buttons/MetaMaskConnectButton";

const styles = require("./noobs.module.scss")

type NoobsProps = {
  accountAddress: string | null,
  contract: Contract,
  getAndSetAccount: Function,
}

/**
 * Main page for Noobs. Most of the site will be on this page
 */
const NoobsBody: React.FC<NoobsProps> = ({ accountAddress, contract, getAndSetAccount }) => {
  // General state
  const [isLoading, setLoading] = useState(false);
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);
  const [transactionUrl, setTransactionUrl] = useState("");

  // Contract state
  const [hasSaleStarted, setHasSaleStarted] = useState(false);
  const [currentSupply, setCurrentSupply] = useState<number>(0)
  const [supplyLimit, setSupplyLimit] = useState<number>(0)

  // Form state
  const [numNoobsToBuy, setNumNoobsToBuy] = useState<string>("1")

  // Function to actually do the minting
  const mintNoob = () => {
    return contract.methods.MINT_PRICE().call().then((currentPrice: number) => {
      const numCandiesBuying: number = parseInt(numNoobsToBuy)
      const gasLimit = 200000 * numCandiesBuying - (((200000 * numCandiesBuying) / 100) * (numCandiesBuying - numCandiesBuying * 0.2))
      return contract.methods.buyMultipleNoobs(numCandiesBuying).send(
        {
          from: accountAddress,
          value: currentPrice * numCandiesBuying,
          gas: gasLimit
        })
        .on('transactionHash', (hash) => {
          setTransactionUrl("https://etherscan.io/tx/" + hash);
      })
    })
  }

  // Sets initial price
  useEffect(() => {
    // Buy candies
    if (isLoading) {
      mintNoob().then(() => {
        setLoading(false)
        // navigate("/success");
      }).catch(e => {
        setLoading(false)
        setShouldShowError(true);
      });
    }
    if (contract && accountAddress) {
      contract.methods.totalSupply().call().then((supply: number) => {
        setCurrentSupply(supply)
      })

      contract.methods.NOOB_LIMIT().call().then((noobLimit: number) => {
        setSupplyLimit(noobLimit)
      })

      contract.methods.hasSaleStarted().call().then((hasSaleStarted: boolean) => {
        setHasSaleStarted(hasSaleStarted)
      })
    }
  }, [contract, accountAddress, isLoading, currentSupply]);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
  }

  const remainingNoobs = hasSaleStarted ? String(supplyLimit - currentSupply) : "~5000";
  const isSoldOut = ((supplyLimit === currentSupply) && hasSaleStarted);

  const { ethereum } = (typeof window !== 'undefined') ? window : { ethereum: null };
  const isMetaMaskInstalled = ethereum && ethereum.isMetaMask;
  const isMobileAndMetamaskNotInstalled = isMobile() && !isMetaMaskInstalled

  return (
    <>
      <Container id="intro" className="d-flex align-items-center justify-content-center">
        <Row className="intro-row">
          <Col className="col-12 col-lg-6 d-flex flex-column align-items-center justify-content-center">
            {(!isMobileAndMetamaskNotInstalled && !accountAddress && !isSoldOut) && (
              <Row className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
                <Col>
                  <div className="noob-metamask-connect-home-page">
                    <MetaMaskConnectButton getAndSetAccount={() => getAndSetAccount()} />
                  </div>
                </Col>
              </Row>
            )}
            {(!isMobileAndMetamaskNotInstalled && accountAddress && !isSoldOut) && (
              <Row className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
                <Form className="d-flex align-items-center justify-content-center" noValidate onSubmit={onSubmit}>
                  <Col className="col-8 col-md-9">
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={!hasSaleStarted}
                      className="noob-mint-button"
                    >
                      Mint
                    </Button>
                  </Col>
                  <Col className="col-4 col-md-3 d-flex align-items-center justify-content-center">
                    <Form.Control
                      as="select"
                      className="noob-mint-input"
                      onChange={({ target: { value } }) => {
                        setNumNoobsToBuy(value);
                      }}
                      value={numNoobsToBuy}
                      disabled={!hasSaleStarted}
                    >
                      {[...Array(20).keys()].map((index) => {
                        const number = index + 1
                        return <option key={number} value={number.toString()}>{number}</option>
                      })}
                    </Form.Control>
                    <p
                      className="noob-max-mint-text"
                      onClick={() => {
                        if (hasSaleStarted) {
                          setNumNoobsToBuy("20")
                        }
                      }}
                    >
                      MAX
                    </p>
                  </Col>
                </Form>
              </Row>
            )}
            {isSoldOut && (
              <>
                <Row className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
                  <Col className="d-flex align-items-center justify-content-center">
                    <h1 className="noobs-sold-out-mint-number">SOLD OUT</h1>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
                  <Col className="d-flex align-items-center justify-content-center">
                    <p className="noobs-sold-out-text">10,000 NOOBS MINTED</p>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
                  <Col>
                    <a href="https://opensea.io/collection/noobsnft" target="_blank">
                      <Button
                        variant="primary"
                        className="noob-mint-button"
                        style={{width: "100%"}}
                      >
                        View on OpenSea
                      </Button>
                    </a>
                  </Col>
                </Row>
              </>
            )}
            {!isSoldOut && (
              <Row>
                <Col className="d-flex align-items-center justify-content-center">
                  <p className="mint-stats-text">0.04 ETH | {remainingNoobs} remaining</p>
                </Col>
              </Row>
            )}
            {(transactionUrl !== "") && (
              <Row className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
                <Col className="d-flex align-items-center justify-content-center">
                  <a href={transactionUrl} target="_blank" className="primary-color-link pending-transaction-link">View last transaction</a>
                </Col>
              </Row>
            )}
          </Col>
          <Col className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
            <Image src={NoobsGif} className="intro-image" />
          </Col>
        </Row>
      </Container>

      <Container id="about" className="noob-section" fluid>
        <Row>
          <Col>
            <Row className="d-flex align-items-center justify-content-center">
              <Col className="col-12 col-sm-12 col-lg-5 d-flex align-items-center justify-content-center">
                <Image src={SiteGrid} className="sample-noobs-image" />
              </Col>
              <Col className="col-0 col-lg-1"></Col>
              <Col className="col-12 col-sm-12 col-lg-6">
                <h1 className={styles.textHeaderNotCentered}>Welcome, NOOBS</h1>
                <p className={styles.textContent}>Welcome to the World of NOOBS, a generative collectibles project made for NOOBS by NOOBS!</p>
                <p className={styles.textContent}>In the not-too-distant future, mainstream adoption of NFTs will overwhelm the Ethereum Blockchain. Enter the NOOBS, the perfect starter collectible for NOOBS learning to navigate the metaverse. NOOBS are a collection of 10,000 NFTs living on the Ethereum Blockchain and hosted on IPFS.</p>
                <p className={styles.textContent}>Your NOOB will serve as your entry into the World of NOOBS, unlocking further benefits over time, including airdrops and other NOOB-exclusive content. Ownership includes creative and commercial rights to your NOOB, as well as proof that you are, in fact, a NOOB! More than anything, your NOOB is meant to be a fun, affordable digital collectible, which you can use to navigate the metaverse and flex your full NOOB credentials!</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container id="team" className="noob-section" fluid>
        <Row>
          <Col>
            <h1 className={styles.textHeader}>Team</h1>
            <Row key="sven" bsPrefix="row noob-cards-row">
              <Col key="sven" className="noob-card">
                <a href="https://twitter.com/sven_nft" target="_blank" className="noob-link">
                  <Card style={{ width: '15rem' }} className="no-border">
                    <Card.Img variant="top" src={sample1} />
                    <Card.Body>
                      <Card.Title style={{ textAlign: "center" }}><FaTwitter className="twitter-icon-image" color={"white"} size={20}/> SVEN</Card.Title>
                      <Card.Text style={{ textAlign: "center" }}>CHIEF NOOB OFFICER</Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col key="mike" className="noob-card">
                <a href="https://twitter.com/mikealikenft" target="_blank" className="noob-link">
                  <Card style={{ width: '15rem' }} className="no-border">
                    <Card.Img variant="top" src={sample2} />
                    <Card.Body>
                      <Card.Title style={{ textAlign: "center" }}><FaTwitter className="twitter-icon-image" color={"white"} size={20}/> MIKE</Card.Title>
                      <Card.Text style={{ textAlign: "center" }}>CHIEF NOOB ARTIST</Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col key="seinfeld" className="noob-card">
                <a href="https://twitter.com/seinfeldnft" target="_blank" className="noob-link">
                  <Card style={{ width: '15rem' }} className="no-border">
                    <Card.Img variant="top" src={sample3} />
                    <Card.Body>
                      <Card.Title style={{ textAlign: "center" }}><FaTwitter className="twitter-icon-image" color={"white"} size={20}/> SEINFELD</Card.Title>
                      <Card.Text style={{ textAlign: "center" }}>CHIEF NOOB ENTHUSIAST</Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col key="heygoomba" className="noob-card">
                <a href="https://twitter.com/GoombaHey" target="_blank" className="noob-link">
                  <Card style={{ width: '15rem' }} className="no-border">
                    <Card.Img variant="top" src={sample4} />
                    <Card.Body>
                      <Card.Title style={{ textAlign: "center" }}><FaTwitter className="twitter-icon-image" color={"white"} size={20}/> HEY GOOMBA</Card.Title>
                      <Card.Text style={{ textAlign: "center" }}>CHIEF NOOB ARCHITECT</Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col key="bfjoslf" className="noob-card">
                <a href="https://twitter.com/bfjoslf" target="_blank" className="noob-link">
                  <Card style={{ width: '15rem' }} className="no-border">
                    <Card.Img variant="top" src={sample5} />
                    <Card.Body>
                      <Card.Title style={{ textAlign: "center" }}><FaTwitter className="twitter-icon-image" color={"white"} size={20}/> BFJOSLF</Card.Title>
                      <Card.Text style={{ textAlign: "center" }}>CHIEF NOOB ENGINEER</Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col key="smosmo" className="noob-card">
                <a href="https://twitter.com/MrSmoSmo" target="_blank" className="noob-link">
                  <Card style={{ width: '15rem' }} className="no-border">
                    <Card.Img variant="top" src={sample6} />
                    <Card.Body>
                      <Card.Title style={{ textAlign: "center" }}><FaTwitter className="twitter-icon-image" color={"white"} size={20}/> SMO SMO</Card.Title>
                      <Card.Text style={{ textAlign: "center" }}>CHIEF NOOB OPERATOR</Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container id="faq" className="noob-section" fluid>
        <Row>
          <Col>
            <h1 className={styles.textHeader}>FAQ</h1>
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header><span className={styles.textQuestion}>What are NOOBS?</span></Accordion.Header>
                <Accordion.Body>
                  A collection of 10,000 NFTs living on the Ethereum Blockchain and hosted on IPFS.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header><span className={styles.textQuestion}>How are NOOBS created?</span></Accordion.Header>
                <Accordion.Body>
                  NOOBS are algorithmically generated by combining 160 traits with varying rarity across six categories (Body, Outfit, Hat, Eyes, Mouth, Background).
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header><span className={styles.textQuestion}>How much does a NOOB cost?</span></Accordion.Header>
                <Accordion.Body>
                  0.04 ETH (plus gas) with a maximum of 20 NOOBS per transaction.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header><span className={styles.textQuestion}>First time buyer?</span></Accordion.Header>
                <Accordion.Body>
                  <p>The first step would be setting up your very own wallet using the <a href="https://metamask.io/" target="_blank" className="primary-color-link">metamask plugin</a> on your browser.</p>
                  <p>Once that is done, you're going to need some ETH. You can either buy this directly through metamask, or via an exchange (we recommend  <a href="https://www.gemini.com/share/vkz9wzh8" target="_blank" className="primary-color-link">Gemini</a> but <a href="https://www.coinbase.com/" target="_blank" className="primary-color-link">Coinbase</a> is another option as well).</p>
                  <p>With your ETH in hand, you are going to want to withdraw to your metamask address (if you purchased on an exchange). Then connect your wallet to our site using the option in the navigation bar.</p>
                  <p>Voilà! Just like that you are now ready to buy some NOOBS  - and may we say we are honored to have been your first foray into this wonderful world.</p>
                  <p>If you have any trouble please message the support channel in our Discord server and we will be more than happy to lend a hand.</p>
                  <span>Please note that our team will never contact you to request any personal or other identifying information. Be especially careful with your wallet and NEVER share your seed phrase with anyone.</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header><span className={styles.textQuestion}>What does ownership entail?</span></Accordion.Header>
                <Accordion.Body>
                  Ownership includes creative and commercial rights to your NOOB, as well as proof that you are, in fact, a NOOB!
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header><span className={styles.textQuestion}>Where can I find other NOOBS?</span></Accordion.Header>
                <Accordion.Body>
                  <span>Follow us on <a href="https://twitter.com/noobsnft" target="_blank" className="primary-color-link">Twitter</a> and join our <a href="https://discord.gg/6rwBtQWeKY" target="_blank" className="primary-color-link">Discord</a> to fully enter the World of Noobs!</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header><span className={styles.textQuestion}>Can I purchase a NOOB on a mobile device?</span></Accordion.Header>
                <Accordion.Body>
                  <span>Only through the <a href="https://metamask.io/" target="_blank" className="primary-color-link">MetaMask</a> mobile browser. Otherwise, we only support purchasing on desktop devices via <a href="https://metamask.io/" target="_blank" className="primary-color-link">MetaMask</a>.</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header><span className={styles.textQuestion}>Where can I learn more about the contract?</span></Accordion.Header>
                <Accordion.Body>
                  <span>You can view any of the transaction, code, etc. on <a href="https://etherscan.io/address/0x7d7f6f004a421f980fc2d4522ba294eddc880a00" target="_blank" className="primary-color-link">Etherscan</a>.</span>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <br/>
    </>
  )
}

export default NoobsBody
